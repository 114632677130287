/* eslint-disable @typescript-eslint/no-explicit-any */
export const snakeToCamel = (str: string): string => {
  return str.replace(/([-_]\w)/g, g => g[1].toUpperCase());
};

export const convertKeysToCamelCase = (obj: any): any => {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(convertKeysToCamelCase);
  }
  return Object.keys(obj).reduce((acc, key) => {
    const camelCaseKey = snakeToCamel(key);
    acc[camelCaseKey] = convertKeysToCamelCase(obj[key]);
    return acc;
  }, {} as any);
};
