export const GanttConsts = {
  ROW_HEIGHT: 40,
  HEADER_HEIGHT: 122,
  TREE_WIDTH: 525,
  LEAF_TITLE_PADDING_LEFT: 20,
  LEAF_CHILDREN_PADDING_LEFT: 10,
  SCALE_STEP_DEFAULT_WIDTH: 50,
  SECONDS_IN_HOUR: 3600,
  SECONDS_IN_DAY: 86400,
  HOURS_IN_DAY: 24,
  MIN_SCROLL_OFFSET: 2400,
  SCALE_STEP_RATIO: 1.6,
  PROJECT_COLOR: 'var(--secondary50)',
  TASK_COLOR: 'var(--primary10)',
};
