import { useState } from 'react';
export const PerformanceIndicator = () => {
  const activeLinkClassName = 'nav-link active';
  const inactiveLinkClassName = 'nav-link';
  const [proposalVisibility, setProposalVisibility] = useState(true);
  const [projectVisibility, setProjectVisibility] = useState(false);
  const [proposalLink, setProposalLink] = useState(activeLinkClassName);
  const [projectLink, setProjectLink] = useState(inactiveLinkClassName);

  const showProposal = () => {
    setProposalVisibility(true);
    setProjectVisibility(false);
    setProjectLink(inactiveLinkClassName);
    setProposalLink(activeLinkClassName);
  };

  const showProject = () => {
    setProposalVisibility(false);
    setProjectVisibility(true);
    setProposalLink(inactiveLinkClassName);
    setProjectLink(activeLinkClassName);
  };

  return (
    <div className="container">
      <ul className="nav nav-tabs">
        <li className="nav-item">
          <a
            className={proposalLink}
            aria-current="page"
            onClick={showProposal}
          >
            Propuestas
          </a>
        </li>
        <li className="nav-item">
          <a className={projectLink} onClick={showProject}>
            Proyectos
          </a>
        </li>
      </ul>
      {proposalVisibility && (
        <div className="row">
          <iframe
            title="propuestas"
            width="800"
            height="600"
            src={process.env.REACT_APP_PROPOSAL_LOOKER_STUDIO}
            style={{ border: 0 }}
            allowFullScreen
          />
        </div>
      )}
      {projectVisibility && (
        <div className="row">
          <iframe
            title="proyectos"
            width="800"
            height="600"
            src={process.env.REACT_APP_PROJECT_LOOKER_STUDIO}
            style={{ border: 0 }}
            allowFullScreen
          ></iframe>
        </div>
      )}
    </div>
  );
};
