import axios from 'axios';
import { projectType } from '../models/project';
import { convertKeysToCamelCase } from '../utils/snakeToCamel';

const getAllProjects = async () => {
  try {
    const url = `${process.env.REACT_APP_API_LAMBDA}/project`;
    const response = await axios.get(url);
    const projects: projectType[] = convertKeysToCamelCase(response.data.data.projects);
    return projects;
  } catch (error) {
    console.error(error);
  }
};

const getProjectsByOwnerId = async (id: string) => {
  try {
    const url = `${process.env.REACT_APP_API_LAMBDA}/project/by-owner?id=${id}`;
    const response = await axios.get(url);
    const projects: projectType[] = convertKeysToCamelCase(response.data);

    return projects;
  } catch (error) {
    console.error(error);
  }
};

const getProjectsByOwnerAreaId = async (id: string) => {
  try {
    const url = `${process.env.REACT_APP_API_LAMBDA}/project/by-area?id=${id}`;
    const response = await axios.get(url);
    const projects: projectType[] = convertKeysToCamelCase(response.data);
    return projects;
  } catch (error) {
    console.error(error);
  }
};

export { getAllProjects, getProjectsByOwnerId, getProjectsByOwnerAreaId };
