import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { MenuType, MenuItemType } from '../../models/menu';

const initialState: MenuType = { menuItems: [], menuItemActive: null };

const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    addMenu: (state, action: PayloadAction<MenuItemType[]>) => {
      state.menuItems = action.payload;
    },
    setMenuStatusItem: (state, action: PayloadAction<{ linkTo: string }>) => {
      const { linkTo } = action.payload;
      const updatedMenuItems = state.menuItems.map((item: MenuItemType) => {
        if (item.linkTo === linkTo) {
          return { ...item, isDisabled: false };
        } else {
          return { ...item, isDisabled: true };
        }
      });

      state.menuItems = updatedMenuItems;
    },
  },
});

export const { addMenu, setMenuStatusItem } = menuSlice.actions;

export default menuSlice.reducer;
