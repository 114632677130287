import React, { CSSProperties, useMemo } from 'react';
import BarsItems from './BarsItems';
// import { BarsRowContext } from './BarsRowContext';
import { GanttItemDataType, OnBarDoubleClickType } from '../../../../types';
import './BarsRow.css';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../app/store';

interface BarsRowProps {
  barData: GanttItemDataType;
  index: number;
  style: CSSProperties;
  onBarDoubleClick?: OnBarDoubleClickType;
}

const BarsRow: React.FC<BarsRowProps> = ({
  barData,
  index,
  style,
  onBarDoubleClick,
}) => {
  const { settings } = useSelector((state: RootState) => state.planner);
  const wrapStyle = useMemo(() => {
    return {
      ...style,
      backgroundImage: `repeating-linear-gradient(to right, var(--gantt-border-color-base) 0px 1px, ${
        index % 2 === 0
          ? 'var(--gantt-background-second)'
          : 'var(--gantt-background-main)'
      } 1px ${settings.stepWidth}px`,
    };
  }, [index, settings.stepWidth, style]);
  return (
    <div className="gantt-bars-row-wrap" style={wrapStyle}>
      <BarsItems
        data={barData.data}
        rowKey={barData.key}
        estimatePerDay={barData.estimatePerDay}
        color={barData.color}
        title={barData.title}
        level={barData.level}
        barKey={barData.key}
        onBarDoubleClick={onBarDoubleClick}
      />
    </div>
  );
};

export default BarsRow;
