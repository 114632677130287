import { BrowserRouter, Route, Routes, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { DocumentManager, PerformanceIndicator, Planner } from './pages';
import {
  Header,
  HeaderBgColor,
  Menu,
  SvgImage,
} from '@geoinnova/geoinnova-core';
import './App.css';
import logoCoflow from './assets/images/new-logo-coflow.svg';
import { Login } from './pages/Login';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from './app/store';
import { addMenu, setMenuStatusItem } from './app/slices/menuSlice';
import { menuItemsInitial } from './constanst/menuConstants';
import { MenuItemType } from './models/menu';
import { useEffect } from 'react';

function App() {
  const dispatch = useDispatch();
  const { login } = useSelector((state: RootState) => state.global);
  const { menuItems } = useSelector((state: RootState) => state.menu);

  const handleClick = (linkTo: string): void => {
    dispatch(setMenuStatusItem({ linkTo }));
  };

  const isItemDisabled = (linkTo: string) => {
    const item = menuItems.find(item => item.linkTo === linkTo);
    return item ? item.isDisabled : true;
  };

  useEffect(() => {
    dispatch(addMenu(menuItemsInitial));
  }, []);

  return (
    <BrowserRouter>
      <Helmet>
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/instantsearch.css@7/themes/satellite-min.css"
        />
      </Helmet>
      <Header
        bgColor={HeaderBgColor.bgWhite}
        logo={<SvgImage src={logoCoflow} />}
        logoOnClick={() => {}}
        otherApps
        withRightIcons
        withRightIconsArray={[]}
        menuHeight="8.5%"
      />
      <div className="container-fluid main-container-fluid">
        <div className="row">
          {!login.loggedIn ? (
            <div className="col force-height-100">
              <Routes>
                <Route caseSensitive element={<Login />} path="/" />
              </Routes>
            </div>
          ) : (
            <>
              <div className="col-2 main-division">
                <div className="sidebar-container pt-6 d-flex flex-column align-items-start">
                  {menuItems.map((element: MenuItemType) => (
                    <Link
                      className="mb-6 w-100"
                      onClick={() => handleClick(element.linkTo)}
                      to={element.linkTo}
                    >
                      <Menu
                        className={
                          isItemDisabled(element.linkTo) ? '' : 'style-active'
                        }
                        linkText={element.linkText}
                        withIcon={false}
                      />
                    </Link>
                  ))}
                </div>
              </div>
              <div className="col-10">
                <Routes>
                  {/* <Route caseSensitive element={<Planner />} path="/" /> */}
                  <Route
                    caseSensitive
                    element={<DocumentManager />}
                    path="/document-manager"
                  />
                  <Route
                    caseSensitive
                    element={<PerformanceIndicator />}
                    path="/perfomance-indicator"
                  />
                  <Route caseSensitive element={<Planner />} path="/planner" />
                </Routes>
              </div>
            </>
          )}
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
