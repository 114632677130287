import { useCallback, useContext } from 'react';
import { DragStepOptions, GanttDimensionsSettings } from '../constants';
import { GanttDimensions } from '../enums';
import { getInitialScrollOffset, getScaleDates } from '../utils';
import { RootState } from '../../../app/store';
import { useDispatch, useSelector } from 'react-redux';
import { setScaleDates, setSettings } from '../../../app/slices/plannerSlice';

export const useGanttCalculate = () => {
  const { settings, wrapRef, currentDate } = useSelector(
    (state: RootState) => state.planner,
  );
  const dispatch = useDispatch();

  const calculate = useCallback(
    (dimension: GanttDimensions) => {
      const {
        stepWidth,
        itemsCount,
        scaleStepItems,
        secondsInPixel,
        unitOfTime,
      } = GanttDimensionsSettings[dimension];

      const newScaleDates = getScaleDates(currentDate, itemsCount, unitOfTime);
      const initialScrollOffset = getInitialScrollOffset(
        dimension,
        newScaleDates,
        currentDate,
      );
      const gridSize =
        DragStepOptions[settings.dragStepSize].seconds / secondsInPixel;

      dispatch(
        setSettings({
          stepWidth,
          initialScrollOffset,
          scaleStepItems,
          secondsInPixel,
          dimension,
          dragStepSize: settings.dragStepSize,
          gridSize,
        }),
      );
      dispatch(setScaleDates(newScaleDates));

      wrapRef?.current?.scrollTo({ left: initialScrollOffset });
    },
    [currentDate, setScaleDates, setSettings, settings.dragStepSize, wrapRef],
  );

  return { calculate };
};
