import React from 'react';
import { IconProps } from '../../types';

export const StepOutlined: React.FC<IconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6663 10.9997V7.33301C14.6663 5.81422 13.4351 4.58301 11.9163 4.58301H10.083C8.56422 4.58301 7.33301 5.81422 7.33301 7.33301V9.16634H4.58301C3.06422 9.16634 1.83301 10.3976 1.83301 11.9163V16.4997C1.83301 17.0059 2.24341 17.4163 2.74967 17.4163H19.2497C19.7559 17.4163 20.1663 17.0059 20.1663 16.4997V13.7497C20.1663 12.2309 18.9351 10.9997 17.4163 10.9997H14.6663ZM11.9163 5.95801H10.083C9.32362 5.95801 8.70801 6.57362 8.70801 7.33301V16.0413H13.2913V7.33301C13.2913 6.57362 12.6757 5.95801 11.9163 5.95801ZM14.6663 12.3747V16.0413H18.7913V13.7497C18.7913 12.9903 18.1757 12.3747 17.4163 12.3747H14.6663ZM4.58301 10.5413H7.33301V16.0413H3.20801V11.9163C3.20801 11.1569 3.82362 10.5413 4.58301 10.5413Z"
        fill="var(--gantt-text-color)"
      />
    </svg>
  );
};
