import React, {
  Dispatch,
  SetStateAction,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { VariableSizeList as List } from 'react-window';
import { GanttConsts } from '../../../constants';
import { GanttItemDataType } from '../../../types';
import './Tree.css';
import {
  Badge,
  BadgeColor,
  BadgeType,
  SvgImage,
  Text,
  TextStyle,
} from '@geoinnova/geoinnova-core';
// import iconPlus from '../../../../../assets/images/icon-plus.svg';
// import iconTriangleExclamation from '../../../../../assets/images/icon-triangle-exclamation.svg';
// import iconClock from '../../../../../assets/images/icon-clock.svg';
import iconAngleRight from '../../../../../assets/images/icon-angle-right.svg';
import iconAngleDown from '../../../../../assets/images/icon-angle-down.svg';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../app/store';
import { setTransformedData as setData } from '../../../../../app/slices/plannerSlice';
// import iconPlusButton from '../../../../../assets/images/icon-plus-button.svg';

interface TreeProps {
  height: number;
  // data: GanttItemDataType[];
  // setData: Dispatch<SetStateAction<GanttItemDataType[]>>;
}

const Tree = forwardRef<List, TreeProps>(({ height }, ref) => {
  const dispatch = useDispatch();

  const { transformedData: data } = useSelector(
    (state: RootState) => state.planner,
  );
  const treeData = useMemo(() => {
    const collapsedKeys: string[] = [];

    for (const item of data) {
      if (item.hasOwnProperty('expanded') && !item.expanded) {
        collapsedKeys.push(item.key);
      }
    }

    return data.filter(item => {
      const isParentCollapsed = item.parentsKeys.some(
        key => collapsedKeys?.includes(key),
      );

      return !isParentCollapsed;
    });
  }, [data]);

  const onLeafClick = useCallback(
    (key: string) => {
      const newData = [...data];
      const element = newData.find(item => item.key === key);
      const elementIndex = newData.findIndex(item => item.key === key);

      if (element?.hasOwnProperty('expanded')) {
        const updatedElement = { ...element, expanded: !element.expanded };
        newData[elementIndex] = updatedElement;
      }

      dispatch(setData(newData));
    },
    [data, setData],
  );

  return (
    <div
      className="gantt-tree-wrap"
      style={{
        width: GanttConsts.TREE_WIDTH,
        marginTop: -GanttConsts.HEADER_HEIGHT,
      }}
    >
      <List
        ref={ref}
        className="gantt-tree-list"
        layout="vertical"
        width="100%"
        height={height}
        itemCount={treeData?.length}
        itemSize={() => GanttConsts.ROW_HEIGHT}
        itemData={treeData}
        innerElementType={({ children, props }) => {
          return (
            <div
              style={{ height: treeData?.length * GanttConsts.ROW_HEIGHT }}
              {...props}
            >
              <div
                className="gantt-tree-title"
                style={{ height: GanttConsts.HEADER_HEIGHT }}
              >
                {/* <Text
                  content="Mis asignaciones"
                  style={TextStyle.h2}
                  className="color-primary70"
                />
                <SvgImage
                  src={iconPlusButton}
                  onClick={() => {}}
                  className="mr-2 icon-plus-button"
                /> */}
              </div>
              <div className="gantt-tree-inner-wrap">{children}</div>
            </div>
          );
        }}
      >
        {({ style, index, data }) => {
          const item = data[index];
          return (
            <div
              className="gantt-tree-list-item"
              style={{
                ...style,
                backgroundColor: `var(--gantt-background-${
                  index % 2 === 0 ? 'second' : 'main'
                })`,
                paddingLeft:
                  GanttConsts.LEAF_TITLE_PADDING_LEFT +
                  item.level * GanttConsts.LEAF_CHILDREN_PADDING_LEFT,
              }}
            >
              <div
                onClick={() => onLeafClick(item.key)}
                className="clickable-container"
              >
                {item.hasOwnProperty('expanded') ? (
                  item.expanded ? (
                    <div
                      className="gantt-tree-icon"
                      style={{ left: item.level === 1 ? '0.75rem' : '0.25rem' }}
                    >
                      <SvgImage src={iconAngleDown} className="icon-arrows" />
                    </div>
                  ) : (
                    <div
                      className="gantt-tree-icon"
                      style={{ left: item.level === 1 ? '0.75rem' : '0.25rem' }}
                    >
                      <SvgImage
                        src={iconAngleRight}
                        className="gantt-tree-icon icon-arrows"
                      />
                    </div>
                  )
                ) : null}
                <div
                  className={`title-section-container d-flex align-items-center ${
                    item.closeToEndTime || item.important
                      ? 'section-container-one-icon'
                      : ''
                  } ${
                    item.closeToEndTime && item.important
                      ? 'section-container-two-icons'
                      : ''
                  } ${
                    item.level === 2
                      ? 'section-container-subtask'
                      : item.level === 1
                        ? 'section-container-task'
                        : ''
                  }`}
                >
                  {/* {item.important && (
                    <SvgImage
                      src={iconTriangleExclamation}
                      className="icon-triangle-exclamation mr-2"
                    />
                  )} */}
                  {/* {item.closeToEndTime && (
                    <SvgImage
                      src={iconClock}
                      className="icon-clock-close-time mr-2"
                    />
                  )} */}
                  <span data-bs-toggle="tooltip" title={data[index].title}>
                    <Text
                      content={data[index].title}
                      style={TextStyle.subtitle1}
                      className={
                        data[index].title?.includes('Jefe de proyecto')
                          ? 'color-neutral70'
                          : item.key.includes('project')
                            ? 'color-primary70'
                            : 'color-dark'
                      }
                    />
                  </span>
                </div>
              </div>
              <div className="d-flex h-100">
                <div
                  className={`column-two d-flex align-items-center bg-white ${
                    item.key.includes('project')
                      ? 'justify-content-center'
                      : 'justify-content-start pl-3'
                  } `}
                >
                  {item.key.includes('project') ? (
                    <span
                      data-bs-toggle="tooltip"
                      title={item.projectStatusFullName}
                    >
                      <Badge
                        type={BadgeType.largeBold}
                        color={BadgeColor.dark}
                        width="49"
                        text={item.projectStatus}
                      />
                    </span>
                  ) : (
                    <>
                      <SvgImage
                        src={item.assignedPersonPhoto}
                        className="photo-image"
                      />
                      <span
                        data-bs-toggle="tooltip"
                        title={item.assignedPersonFullName}
                      >
                        <Text
                          content={item.assignedPerson}
                          style={TextStyle.subtitle1}
                          className="ml-2"
                        />
                      </span>
                    </>
                  )}
                </div>
                {/* <div className="column-three d-flex align-items-center justify-content-center bg-white">
                  {item.key.includes('project') ? (
                    <Badge
                      type={BadgeType.largeBold}
                      color={BadgeColor.secondary}
                      width="49"
                      text="60%"
                    />
                  ) : item.taskOnTime ? (
                    <>
                      <SvgImage src={iconClock} className="icon-clock" />
                      <p>{item.taskOnTime}</p>
                    </>
                  ) : (
                    <>
                      <SvgImage
                        src={iconClock}
                        className="icon-clock-no-time"
                      />
                      <p>{item.taskOnTime}</p>
                    </>
                  )}
                </div>
                <div className="column-four d-flex align-items-center justify-content-center bg-white">
                  {item.key.includes('project') ? (
                    <SvgImage
                      src={iconPlus}
                      className="icon-plus icon-plus-project"
                      onClick={() => {}}
                    />
                  ) : (
                    <SvgImage
                      src={iconPlus}
                      className="icon-plus"
                      onClick={() => {}}
                    />
                  )}
                </div> */}
              </div>
            </div>
          );
        }}
      </List>
    </div>
  );
});

Tree.displayName = 'Tree';

export default Tree;
