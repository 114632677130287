import axios from 'axios';

const getTaskById = async (id: string) => {
  try {
    const url = `${process.env.REACT_APP_API_LAMBDA}/project/task?id=${id}`;
    const response = await axios.get(url);
  } catch (error) {
    console.error(error);
  }
};

const addTaskTimeEstimatedPerDay = async (
  taskId: string,
  hours: Number,
  date: string,
) => {
  try {
    const url = `${process.env.REACT_APP_API_LAMBDA}/task/time-estimated`;
    const response = await axios.post(url, {
      task_id: taskId,
      hours,
      date,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const setTaskTimeEstimatedPerDay = async (id: string, hours: Number) => {
  try {
    const url = `${process.env.REACT_APP_API_LAMBDA}/task/time-estimated`;
    const response = await axios.put(url, {
      id,
      hours,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export { getTaskById, setTaskTimeEstimatedPerDay, addTaskTimeEstimatedPerDay };
