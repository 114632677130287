import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import cn from 'classnames';
import debounce from 'lodash/debounce';
import { VariableSizeList as List } from 'react-window';
import Bars from './Bars';
import Scale from './Scale';
import Tree from './Tree';
import { GanttConsts, GanttDimensionsSettings } from '../../constants';
import { OnBarChangeType, OnBarDoubleClickType } from '../../types';
import './Chart.css';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import { setCurrentDate } from '../../../../app/slices/plannerSlice';

interface ChartProps {
  className?: string;
  onBarDoubleClick?: OnBarDoubleClickType;
  onBarChange?: OnBarChangeType;
}

const Chart: React.FC<ChartProps> = ({
  className,
  onBarDoubleClick,
  onBarChange,
}) => {
  const { settings, scaleDates } = useSelector(
    (state: RootState) => state.planner,
  );
  const dispatch = useDispatch();
  const scaleRef = useRef<List>(null);
  const treeRef = useRef<List>(null);
  const barsRef = useRef<List>(null);

  const [loading, setLoading] = useState(true);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const wrapRefLocal = useRef<HTMLDivElement | null>(null);

  const setNewCurrentDate = useCallback(() => {
    const secondsDiff = Math.round(
      ((wrapRefLocal?.current?.scrollLeft || 0) + GanttConsts.TREE_WIDTH) *
        GanttDimensionsSettings[settings.dimension].secondsInPixel,
    );
    dispatch(setCurrentDate(scaleDates[0] + secondsDiff));
  }, [scaleDates, settings.dimension, wrapRefLocal]);

  const debounceSetCurrentDate = useMemo(() => {
    return debounce(setNewCurrentDate, 100);
  }, [setNewCurrentDate]);

  useEffect(() => {
    if (
      wrapRefLocal?.current?.clientWidth &&
      wrapRefLocal?.current?.clientHeight
    ) {
      setWidth(wrapRefLocal.current.clientWidth - GanttConsts.TREE_WIDTH);
      setHeight(wrapRefLocal.current.clientHeight - GanttConsts.HEADER_HEIGHT);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!loading && wrapRefLocal?.current) {
      wrapRefLocal.current.scrollLeft = settings.initialScrollOffset;
    }
  }, [loading]);

  return (
    wrapRefLocal && (
      <div
        className={cn('gantt-chart-wrap', className)}
        ref={wrapRefLocal}
        onScroll={evt => {
          barsRef.current?.scrollTo(evt.currentTarget.scrollTop);
          treeRef.current?.scrollTo(evt.currentTarget.scrollTop);
          scaleRef.current?.scrollTo(evt.currentTarget.scrollLeft);
          // debounceSetCurrentDate();
        }}
      >
        {loading ? (
          <div
            style={{
              width:
                settings?.scaleStepItems *
                settings?.stepWidth *
                scaleDates.length,
            }}
          />
        ) : (
          <>
            <Scale width={width} wrapRef={wrapRefLocal} ref={scaleRef} />
            <Tree height={height} ref={treeRef} />

            <Bars
              ref={barsRef}
              width={width}
              height={height}
              onBarDoubleClick={onBarDoubleClick}
              onBarChange={onBarChange}
            />
          </>
        )}
      </div>
    )
  );
};

export default Chart;
