import { configureStore } from '@reduxjs/toolkit';
import planningReducer from './slices/plannerSlice';
import globalReducer from './slices/globalSlice';
import menuReducer from './slices/menuSlice';
import userReducer from './slices/userSlice';

const store = configureStore({
  reducer: {
    planner: planningReducer,
    global: globalReducer,
    menu: menuReducer,
    user: userReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
