import { initializeApp } from 'firebase/app';
import { getAnalytics, setUserId } from 'firebase/analytics';
import { Dispatch, UnknownAction } from '@reduxjs/toolkit';
import {
  OAuthProvider,
  getAuth,
  signInWithPopup,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signInWithRedirect,
} from 'firebase/auth';
import { getInitials } from './utilities';
import { addUserData, deleteUserData, userType } from '../app/slices/userSlice';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSASING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASURENMENT_ID,
};

const app = initializeApp(firebaseConfig);
const provider = new OAuthProvider('microsoft.com');
provider.setCustomParameters({
  prompt: 'select_account',
  tenant: 'c52d63be-852b-4212-97f0-b694d3c769c3',
});

const auth = getAuth(app);
const analytics = getAnalytics(app);

export const signInUser = async (username: string, password: string) => {
  const auth = getAuth();
  try {
    await signInWithEmailAndPassword(auth, username, password);
    return true;
  } catch (err) {
    return false;
  }
};

export const signInUserWithMicrosoft = async (
  dispatch: Dispatch<UnknownAction>,
) => {
  const auth = getAuth();
  try {
    const result = await signInWithPopup(auth, provider);
    if (result) {
      const credential = OAuthProvider.credentialFromResult(result);
      const accessToken = credential?.accessToken;
      if (accessToken) {
        try {
          setUserId(analytics, auth?.currentUser?.uid || '');
        } catch (_error) {}
        dispatch(
          addUserData({
            name: auth?.currentUser?.displayName || '',
            abbreviation: getInitials(auth?.currentUser?.displayName || ''),
            userIdFirestore: auth.currentUser?.uid,
          } as userType),
        );

        return true;
      }
    } else {
      return false;
    }
  } catch (_error) {
    return false;
  }
  return auth.currentUser;
};

export const subscribeOnAuthStateChanged = (
  dispatch: Dispatch<UnknownAction>,
) => {
  onAuthStateChanged(auth, async user => {
    if (user) {
      const refreshToken = user?.refreshToken;
      if (refreshToken) {
        user.getIdTokenResult(true).then(IdTokenResult => {});
        try {
          setUserId(analytics, auth?.currentUser?.uid || '');
        } catch (_error) {}
        dispatch(
          addUserData({
            name: auth?.currentUser?.displayName || '',
            abbreviation: getInitials(auth?.currentUser?.displayName || ''),
            userIdFirestore: auth.currentUser?.uid,
          } as userType),
        );
      }
    } else {
      dispatch(deleteUserData());
    }
  });
};
