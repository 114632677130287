import { useDispatch, useSelector } from 'react-redux';
import {
  Text,
  TextStyle,
  Input,
  Button,
  ButtonStyle,
} from '@geoinnova/geoinnova-core';
import './login.css';
import { signInUser, signInUserWithMicrosoft } from '../../utils/firebase';
import { useNavigate } from 'react-router';
import { setCalendar, setProjects } from '../../app/slices/plannerSlice';
import { getAllProjects } from '../../services/projectServices';
import { getCalendar } from '../../services/calendarServices';
import {
  setIsReady,
  setLoggedIn,
  setPassword,
  setShowLoader,
  setUsername,
} from '../../app/slices/globalSlice';
import { RootState } from '../../app/store';

export const Login = () => {
  const dispatch = useDispatch();
  const linkTo = '/';
  const navigate = useNavigate();
  const { login } = useSelector((state: RootState) => state.global);

  const handleUserName = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setUsername(event.target.value));
  };

  const handlePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setPassword(event.target.value));
  };

  const handleSubmitLoginWithMicrosoft = async () => {
    try {
      dispatch(setShowLoader(true));
      const signResponse = await signInUserWithMicrosoft(dispatch);
      if (signResponse) {
        dispatch(setLoggedIn(true));
        navigate(linkTo);
        const responseProjects = await getAllProjects();
        const responseCalendar = await getCalendar();
        dispatch(setProjects(responseProjects || []));
        dispatch(setCalendar(responseCalendar || []));
        dispatch(setIsReady(true));
        dispatch(setShowLoader(false));
      }
    } catch (error) {
      dispatch(setLoggedIn(false));
    }
  };

  const handleSubmitLogin = async () => {
    try {
      dispatch(setShowLoader(true));
      const signResponse = await signInUser(login.username, login.password);
      if (signResponse) {
        dispatch(setLoggedIn(true));
        navigate(linkTo);
        const responseProjects = await getAllProjects();
        const responseCalendar = await getCalendar();
        dispatch(setProjects(responseProjects || []));
        dispatch(setCalendar(responseCalendar || []));
        dispatch(setIsReady(true));
        dispatch(setShowLoader(false));
      }
    } catch (error) {
      dispatch(setLoggedIn(false));
    }
  };

  return (
    <div className="row justify-content-center bg-neutral30 h-100">
      <div className="col-xxl-3 col-md-8">
        <div className="d-flex align-items-center justify-content-center mt-10">
          <div className="card-container p-10 w-100">
            <Text
              content="Iniciar sesión"
              style={TextStyle.h1}
              className="color-primary100 mb-6"
            />
            <Input
              name="username"
              label="Usuario"
              className="mb-5"
              value={login.username}
              onChange={handleUserName}
            />
            <Input
              type="password"
              name="password"
              label="Contraseña"
              className="mb-5"
              value={login.password}
              onChange={handlePassword}
            />
            <div className="d-flex justify-content-center">
              <Button
                label="Iniciar sesión"
                onClick={handleSubmitLogin}
                style={ButtonStyle.primary}
              />
            </div>
            <div className="d-flex justify-content-center mt-5">
              <Button
                label="Iniciar sesión con Microsoft"
                onClick={handleSubmitLoginWithMicrosoft}
                style={ButtonStyle.secondary}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
