import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type userType = {
  name: string;
  userIdFirestore: string;
  abbreviation: string;
  userLogged: boolean;
};

const initialState: userType = {
  name: '',
  abbreviation: '',
  userIdFirestore: '',
  userLogged: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    addUserData: (state, action: PayloadAction<userType>) => {
      const { name, abbreviation, userIdFirestore } = action.payload;
      state.name = name;
      state.abbreviation = abbreviation;
      state.userIdFirestore = userIdFirestore;
      state.userLogged = true;
    },
    deleteUserData: state => {
      state.name = '';
      state.abbreviation = '';
      state.userIdFirestore = '';
      state.userLogged = false;
    },
  },
});
export const { addUserData, deleteUserData } = userSlice.actions;

export default userSlice.reducer;
