import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Text,
  TextStyle,
  // Button,
  // ButtonStyle,
  // Dropdown,
  // MultiSelectCustom,
} from '@geoinnova/geoinnova-core';
import { ModalDetails } from './partials/ModalDetails';
// import filterIcon from '../../assets/images/filter-icon.svg';
// import {
//   dummyClientsName,
//   filterParameters,
//   optionsAssignments,
//   optionsProjectStatus,
//   optionsTaskStatus,
//   optionsClientType,
//   dummyNames,
// } from './types';
import { projectType } from '../../models/project';
import { taskType } from '../../models/task';
import {
  InstantSearch,
  SearchBox,
  Hits,
  RefinementList,
} from 'react-instantsearch';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { Hit as AlgoliaHit } from 'instantsearch.js';
import algoliaSearchClient from '../../utils/algoliaSearch';
import noPhotoIcon from '../../assets/images/icon-circle-user.svg';
import { RootState } from '../../app/store';
import {
  EstimatePerDayType,
  GanttDataType,
  GanttItemDataType,
} from '../../components/GanttCustom/types';
import { GanttConsts } from '../../components/GanttCustom/constants/GanttConsts';
import {
  differenceDay,
  generateRandom,
  setDefaultDate,
} from '../../utils/utilities';

import './partials/GanttChart/index.css';
import {
  Gantt,
  getInitialScrollOffset,
  getScaleDates,
  transformData,
} from '../../components/GanttCustom';

import {
  GanttDimensions,
  DragStepSizes,
} from '../../components/GanttCustom/enums';
import {
  GanttDimensionsSettings,
  DragStepOptions,
} from '../../components/GanttCustom/constants';

import {
  setCurrentDate,
  setSettings,
  setTasks,
  setTransformedData,
} from '../../app/slices/plannerSlice';
import dayjs from 'dayjs';
import _ from 'lodash';
dayjs.extend(localizedFormat);

type HitProps = {
  hit: AlgoliaHit<projectType>;
};

function Hit({ hit }: HitProps) {
  return (
    <>
      <span className="Hit-price">{hit.name}</span>
    </>
  );
}

export const Planner = () => {
  const dispatch = useDispatch();
  const [openModalProjectDetails, setOpenModalProjectDetails] =
    useState<boolean>(false);
  const [openModalMainTaskDetails, setOpenModalMainTaskDetails] =
    useState<boolean>(false);
  const [modalTypeProject] = useState<boolean>(true);
  const [modalTypeTask] = useState<boolean>(false);
  const [currentProject, setCurrentProject] = useState<projectType | null>(
    null,
  );
  const { projects, tasks, transformedData } = useSelector(
    (state: RootState) => state.planner,
  );

  const [currentMainTask, setCurrentMainTask] = useState<taskType | null>(null);
  const [showHits, setShowHits] = useState(false);
  // const [assignments, setAssignments] = useState([
  //   { label: 'Mis asignaciones', value: 'opcion1' },
  // ]);
  // const [projectStatus, setProjectStatus] = useState([]);
  // const [taskStatus, setTaskStatus] = useState([]);
  // const [clientsName, setClientsName] = useState([]);
  // const [clientType, setClientType] = useState([]);
  // const [professionalsName, setProfessionalsName] = useState([]);

  const initialScaleDates = useMemo(() => {
    return getScaleDates();
  }, []);
  const initialScrollOffset = useMemo(() => {
    return getInitialScrollOffset(GanttDimensions.DAY, initialScaleDates);
  }, [initialScaleDates]);
  useEffect(() => {
    if (projects) {
      try {
        let projectAux: GanttDataType[] = projects
          // .filter(
          //   project =>
          //     project.tasks?.some(
          //       task =>
          //         task.estimatedTimePerDay?.length > 0 ||
          //         task.subtasks?.some(
          //           subtask => subtask.estimatedTimePerDay?.length > 0,
          //         ),
          //     ),
          // )
          .map((project: projectType) => {
            return {
              key: 'project-' + project.id,
              title: project.name,
              projectStatus: 'RP',
              projectStatusFullName: 'Reporte',
              closeToEndTime: false,
              important: false,
              color: GanttConsts.PROJECT_COLOR,
              data: {
                startDate:
                  (project?.tasks || [])?.length > 0
                    ? setDefaultDate(project.tasks[0].startDate)
                    : '',
                endDate:
                  (project?.tasks || [])?.length > 0
                    ? setDefaultDate(project.tasks[0].endDate)
                    : '',
              },
              children:
                project.tasks && project.tasks?.length > 0
                  ? project.tasks.map(task => {
                      const dateStart = setDefaultDate(task.startDate);
                      const dateEnd = setDefaultDate(task.endDate);

                      return {
                        key: 'task-' + project.id + '-key' + task.id,
                        title: task.title,
                        assignedPerson: task.responsible?.abbreviation || 'NN',
                        assignedPersonFullName:
                          task.responsible?.name || 'Sin nombre',
                        assignedPersonPhoto:
                          task.responsible?.profileImage || noPhotoIcon,
                        color: GanttConsts.TASK_COLOR,
                        estimatePerDay: task?.estimatedTimePerDay || [],
                        taskOnTime: false,
                        data: {
                          startDate: dateStart,
                          endDate: dateEnd,
                        },
                        children:
                          task.subtasks && task.subtasks?.length > 0
                            ? task.subtasks.map(subtasks => {
                                return {
                                  key:
                                    'task-' + project.id + '-key' + subtasks.id,
                                  title: subtasks.title,
                                  assignedPerson:
                                    subtasks.responsible?.abbreviation || 'NN',
                                  assignedPersonPhoto:
                                    subtasks.responsible?.profileImage ||
                                    noPhotoIcon,
                                  estimatePerDay:
                                    (subtasks.estimatedTimePerDay as EstimatePerDayType[]) ||
                                    [],
                                  color: GanttConsts.TASK_COLOR,
                                  taskOnTime: false,
                                  data: {
                                    startDate: setDefaultDate(
                                      subtasks.startDate,
                                    ),
                                    endDate: setDefaultDate(
                                      subtasks.endDate,
                                      true,
                                    ),
                                  },
                                };
                              })
                            : [],
                      };
                    })
                  : [],
            } as unknown as GanttDataType;
          });
        dispatch(setTasks(projectAux));
        dispatch(setTransformedData(transformData(projectAux)));
      } catch (e) {
        console.log('ERROR', e);
      }
    }
  }, [projects]);

  useEffect(() => {
    dispatch(setCurrentDate(dayjs().unix()));
    dispatch(
      setSettings({
        stepWidth: GanttDimensionsSettings[GanttDimensions.DAY].stepWidth,
        secondsInPixel:
          GanttDimensionsSettings[GanttDimensions.DAY].secondsInPixel,
        scaleStepItems: GanttConsts.HOURS_IN_DAY / 24,
        initialScrollOffset,
        dimension: GanttDimensions.DAY,
        dragStepSize: DragStepSizes.THIRTY_MIN,
        gridSize:
          DragStepOptions[DragStepSizes.THIRTY_MIN].seconds /
          GanttDimensionsSettings[GanttDimensions.DAY].secondsInPixel,
      }),
    );
  }, []);

  // const [loading, setLoading] = useState(true);
  // useEffect(() => {
  //   if (wrapRef.current !== null) {
  //     const clientWidth = wrapRef.current.clientWidth;
  //     const clientHeight = wrapRef.current.clientHeight;
  //     setWidth(clientWidth - GanttConsts.TREE_WIDTH);
  //     setHeight(clientHeight - GanttConsts.HEADER_HEIGHT);
  //     // setLoading(false);
  //   }
  // }, [wrapRef]);
  // const handleExpanderClick = (task: GanttDataType) => {
  //   setTasks(tasks.map(t => (t.id === task.id ? task : t)));
  // };

  // const handleClickProjectLink = (data: Task) => {
  //   switch (data.type) {
  //     case 'project':
  //       const updatedCurrentProject = projects?.find(
  //         (project: projectType) => project.id === data.id,
  //       );
  //       if (updatedCurrentProject) {
  //         setCurrentProject(updatedCurrentProject || null);
  //         setOpenModalProjectDetails(true);
  //         setOpenModalMainTaskDetails(false);
  //       }
  //       break;
  //     case 'task':
  //       projects?.forEach((project: projectType) => {
  //         if (project?.tasks && project.tasks.length > 0) {
  //           const taskAux = project.tasks.find(
  //             (task: taskType) => task.id === data.id,
  //           );

  //           if (taskAux) {
  //             setCurrentMainTask(taskAux);
  //             setOpenModalProjectDetails(false);
  //             setOpenModalMainTaskDetails(true);
  //           }
  //         }
  //       });
  //       break;
  //     default:
  //       break;
  //   }
  // };

  const handleCloseDetails = () => {
    setOpenModalProjectDetails(false);
    setOpenModalMainTaskDetails(false);
  };

  const handleCurrentMainTask = (task: taskType) => {
    setCurrentMainTask(task);
    setCurrentProject(null);
    setOpenModalProjectDetails(false);
    setOpenModalMainTaskDetails(true);
  };

  const GanttComponent = () => {
    return (
      <div className="gantt-container">
        <Gantt>
          <Gantt.Chart />
        </Gantt>
      </div>
    );
  };

  return (
    <div className="pl-4 pt-6">
      <Text
        content="Gestor de proyectos"
        style={TextStyle.h1}
        className="color-primary70 mb-6"
      />
      <div className="mb-9">
        <InstantSearch
          searchClient={algoliaSearchClient}
          indexName={`${process.env.REACT_APP_INDEX_PROJECT_NAME}`}
          insights
        >
          <SearchBox
            placeholder="Buscador"
            onFocus={() => setShowHits(true)}
            onBlur={() => setShowHits(false)}
          />
          <RefinementList attribute="brand" />
          {showHits ? (
            <Hits className="overflow-hits" hitComponent={Hit} />
          ) : null}
        </InstantSearch>
      </div>
      {/* <div className="d-flex justify-content-between mt-5 mb-7">
        <div className="d-flex align-items-center">
          <MultiSelectCustom
            options={optionsAssignments}
            value={assignments}
            onChange={setAssignments}
            selectSomeItems="Asignaciones"
            allItemsAreSelected="Todo seleccionado"
            width="11.25rem"
            className="mr-4"
          />
          <MultiSelectCustom
            options={optionsProjectStatus}
            value={projectStatus}
            onChange={setProjectStatus}
            selectSomeItems="Estado proyecto"
            allItemsAreSelected="Todo seleccionado"
            width="11.25rem"
            className="mr-4"
          />
          <MultiSelectCustom
            options={optionsTaskStatus}
            value={taskStatus}
            onChange={setTaskStatus}
            selectSomeItems="Estado tarea"
            allItemsAreSelected="Todo seleccionado"
            width="11.25rem"
            className="mr-4"
          />
          <MultiSelectCustom
            options={dummyClientsName}
            value={clientsName}
            onChange={setClientsName}
            selectSomeItems="Cliente"
            allItemsAreSelected="Todo seleccionado"
            width="11.25rem"
            className="mr-4"
          />
          <MultiSelectCustom
            options={optionsClientType}
            value={clientType}
            onChange={setClientType}
            selectSomeItems="Tipo de cliente"
            allItemsAreSelected="Todo seleccionado"
            width="11.25rem"
            className="mr-4"
          />
          <MultiSelectCustom
            options={dummyNames}
            value={professionalsName}
            onChange={setProfessionalsName}
            selectSomeItems="Profesional"
            allItemsAreSelected="Todo seleccionado"
            width="11.25rem"
            className="mr-4"
          />
          <Button
            label="Borrar filtros"
            onClick={() => {}}
            style={ButtonStyle.outlineDark}
            customSize="btn-sm"
          />
        </div>
        <div>
          <Dropdown
            label="Ordenar por"
            btnIconDropdown={filterIcon}
            optionList={filterParameters}
            className="drop-icon-style"
          />
        </div>
      </div> */}
      <div>
        <GanttComponent />
      </div>
      {currentProject !== null && (
        <ModalDetails
          show={openModalProjectDetails}
          handleClose={handleCloseDetails}
          handleChildModal={handleCurrentMainTask}
          typeProject={modalTypeProject}
          project={currentProject}
          mainTask={null}
        />
      )}
      {currentMainTask !== null && (
        <ModalDetails
          show={openModalMainTaskDetails}
          handleClose={handleCloseDetails}
          handleChildModal={handleCurrentMainTask}
          typeProject={modalTypeTask}
          project={null}
          mainTask={currentMainTask}
        />
      )}
    </div>
  );
};
