import React from 'react';
import { Text, TextStyle } from '@geoinnova/geoinnova-core';
import es from 'dayjs/locale/es';
import dayjs from 'dayjs';
import { getCurrentDay } from '../../../../../utils/utilities';
dayjs.locale(es);
interface ListItemProps {
  index: number;
  style: React.CSSProperties;
}

const ListItem: React.FC<any> = ({
  item,
  settings,
  data,
  dayOfWeek,
  i,
  index,
}) => {
  return (
    <div className="d-flex flex-column">
      <div
        className="d-flex align-items-center justify-content-start"
        style={{
          minHeight: '38px',
          borderTop: '0.0625rem solid var(--neutral50)',
          borderLeft: !['', null, undefined]?.includes(item.weekYear)
            ? '0.0625rem solid var(--neutral50)'
            : '',
          paddingLeft: !['', null, undefined]?.includes(item.weekYear)
            ? '0.5rem'
            : '',
        }}
      >
        <div className="container-week">
          <Text
            content={item.weekYear}
            style={TextStyle.subtitle1}
            className="color-neutral70"
          />
        </div>
      </div>
      <div
        className="gantt-scale-step d-flex flex-column"
        key={item.key}
        style={{
          width: settings.stepWidth,
        }}
      >
        {(() => {
          const offset = dayjs.unix(data[index]).day() + i - 1;
          for (let x = 0; x < dayOfWeek?.length; x++) {
            let pointer = (x + offset) % dayOfWeek?.length;
            return (
              <Text
                content={dayOfWeek[pointer]}
                style={TextStyle.subtitle2}
                className="color-neutral50 mb-2"
              />
            );
          }
        })()}
        <div
          className="d-flex align-items-center justify-content-center container-day-number"
          style={{
            backgroundColor:
              item.key === getCurrentDay()
                ? 'var(--secondary100)'
                : 'var(--white)',
          }}
        >
          <Text
            content={item.value}
            style={TextStyle.subtitle2}
            className={
              item.key === getCurrentDay() ? 'color-white' : 'color-dark'
            }
          />
        </div>
      </div>
    </div>
  );
};
export default React.memo(ListItem);
