import dayjs from 'dayjs';
import { GanttConsts, GanttDimensionsSettings } from '../constants';
import { GanttDimensions, GanttUnitOfTimes } from '../enums';
import { calendarDay } from '../types';
import moment from 'moment';

export const getScaleItems = (
  dimension: GanttDimensions,
  date: number,
): Array<calendarDay> => {
  const period = GanttDimensionsSettings[dimension].unitOfTime;
  const dateAux = dayjs.unix(date);
  switch (period) {
    case GanttUnitOfTimes.DAY: {
      const size =
        GanttConsts.HOURS_IN_DAY / GanttDimensionsSettings[dimension].hours;
      let start = 0;

      return new Array(size).fill(0).map((item, index) => {
        if (size < GanttConsts.HOURS_IN_DAY) {
          const firstNumber = start;
          const secondNumber = GanttConsts.HOURS_IN_DAY / size + start;

          start = secondNumber;

          return {
            key: firstNumber.toLocaleString('es-CL'),
            value: `${firstNumber.toLocaleString('es-CL', {
              minimumIntegerDigits: 2,
            })} - ${secondNumber.toLocaleString('es-CL', {
              minimumIntegerDigits: 2,
            })}`,
            weekYear: '',
          };
        }

        return {
          key: index.toLocaleString('es-CL'),
          value: index.toLocaleString('es-CL', { minimumIntegerDigits: 2 }),
          weekYear: '',
        };
      });
    }

    case GanttUnitOfTimes.MONTH: {
      const size = moment(
        new Date(dateAux.year(), dateAux.month(), dateAux.day()),
      ).daysInMonth();
      const formatWithOutDay = dateAux.format('MMYYYY');

      const arrayData = new Array(size).fill(0).map((_item, index) => {
        const dayFormat = (index + 1).toLocaleString('es-Es', {
          minimumIntegerDigits: 2,
        });

        const start = moment(dateAux.format('YYYY-MM-') + dayFormat);
        const weekYear = start.isoWeek();
        const firstDay = moment()
          .year(dateAux.year())
          .isoWeek(weekYear)
          .startOf('isoWeek')
          .format('DDMMYYYY');
        const day = dayFormat + formatWithOutDay;
        return {
          key: day,
          value: dayFormat,
          weekYear:
            day === firstDay ? 'Semana ' + start.isoWeek().toString() : '',
        };
      });

      return arrayData;

      // const size = dayjs.unix(date).daysInMonth();

      // const formatWithOutDay = dayjs.unix(date).format('MMYYYY');

      // return new Array(size).fill(0).map((_item, index) => {
      //   const dayFormat = (index + 1).toLocaleString('es-Es', {
      //     minimumIntegerDigits: 2,
      //   });
      //   return {
      //     key: dayFormat + formatWithOutDay,
      //     value: dayFormat,
      //   };
      // });
    }

    default:
      return [];
  }
};
