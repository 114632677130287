import { Draft, PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  ListOnItemsRenderedProps,
  plannerSettingType,
  plannerType,
  plannerWrapRef,
} from '../../models/planningType';
import { projectType } from '../../models/project';
import { calendarType } from '../../models/calendar';
import React from 'react';
import {
  GanttDimensions,
  DragStepSizes,
} from '../../components/GanttCustom/enums';
import {
  getScaleDates,
  transformData,
} from '../../components/GanttCustom/utils';
import {
  EstimatePerDayType,
  GanttDataType,
  GanttItemDataType,
} from '../../components/GanttCustom';
import { startCase, take } from 'lodash';

const initialState: plannerType = {
  barData: {
    key: '',
    title: '',
    level: 0,
    parentsKeys: [''],
    color: '',
  },
  projects: [],
  currentDate: 0,
  scaleDates: getScaleDates(),
  calendar: { events: [], holidays: [] },
  settings: {
    stepWidth: 0,
    secondsInPixel: 0,
    scaleStepItems: 0,
    initialScrollOffset: 0,
    dimension: GanttDimensions.DAY,
    dragStepSize: DragStepSizes.THIRTY_MIN,
    gridSize: 0,
  },
  scaleRenderState: {
    overscanStartIndex: 0,
    overscanStopIndex: 0,
    visibleStartIndex: 0,
    visibleStopIndex: 0,
  },
  tasks: [],
  transformedData: [],
  wrapRef: React.createRef(),
};
const plannerSlice = createSlice({
  name: 'planner',
  initialState,
  reducers: {
    setProjects: (state, action: PayloadAction<projectType[]>) => {
      state.projects = action.payload;
    },
    setCurrentDate: (state, action: PayloadAction<number>) => {
      state.currentDate = action.payload;
    },
    setCalendar: (state, action: PayloadAction<calendarType>) => {
      state.calendar = action.payload;
    },
    setScaleDates: (state, action: PayloadAction<number | number[]>) => {
      if (typeof action.payload === 'number') {
        state.scaleDates = [...state.scaleDates, action.payload];
      } else if (Array.isArray(action.payload)) {
        state.scaleDates = [...state.scaleDates, ...action.payload];
      }
    },
    setScaleRender: (
      state,
      action: PayloadAction<ListOnItemsRenderedProps>,
    ) => {
      state.scaleRenderState = action.payload;
    },
    setSettings: (state, action: PayloadAction<plannerSettingType>) => {
      state.settings = action.payload;
    },
    setTasks: (state, action: PayloadAction<GanttDataType[]>) => {
      state.tasks = action.payload;
    },
    setEstimatedPerDay: (
      state,
      action: PayloadAction<{ id: string | number; hours: number }>,
    ) => {
      state.projects = state.projects.map(project => ({
        ...project,
        tasks:
          project.tasks?.map(task => ({
            ...task,
            subtasks:
              task.subtasks?.map(subtask => ({
                ...subtask,
                estimatedTimePerDay:
                  subtask?.estimatedTimePerDay?.map(estimatedTime =>
                    estimatedTime.id === action.payload.id
                      ? { ...estimatedTime, hours: action.payload.hours }
                      : estimatedTime,
                  ) || subtask.estimatedTimePerDay,
              })) || task.subtasks,
            estimatedTimePerDay:
              task?.estimatedTimePerDay?.map(estimatedTime =>
                estimatedTime.id === action.payload.id
                  ? { ...estimatedTime, hours: action.payload.hours }
                  : estimatedTime,
              ) || task.estimatedTimePerDay,
          })) || project.tasks,
      })) as Draft<projectType[]>;
    },
    addEstimatedPerDay: (
      state,
      action: PayloadAction<{
        id: string;
        estimatedId: string | number;
        hours: number;
        date: Date | string;
      }>,
    ) => {
      state.projects = state.projects.map(project => ({
        ...project,
        tasks:
          project.tasks?.map(task => ({
            ...task,
            subtasks:
              task.subtasks?.map(subtask =>
                subtask.id === action.payload.id
                  ? {
                      ...subtask,
                      estimatedTimePerDay: subtask.estimatedTimePerDay
                        ? [
                            ...subtask.estimatedTimePerDay,
                            {
                              id: action.payload.estimatedId,
                              hours: action.payload.hours,
                              date: action.payload.date,
                            } as EstimatePerDayType,
                          ]
                        : [],
                    }
                  : subtask,
              ) || [],
            estimatedTimePerDay:
              task.id === action.payload.id
                ? [
                    ...task.estimatedTimePerDay,
                    {
                      id: action.payload.estimatedId,
                      hours: action.payload.hours,
                      date: action.payload.date,
                    } as EstimatePerDayType,
                  ]
                : task.estimatedTimePerDay || [],
          })) || project.tasks,
      })) as Draft<projectType[]>;
    },
    setTransformedData: (state, action: PayloadAction<GanttItemDataType[]>) => {
      state.transformedData = action.payload;
    },
    setWrapRef: (state, action: PayloadAction<plannerWrapRef>) => {
      state.wrapRef = action.payload as Draft<plannerWrapRef>;
    },
  },
});

export const {
  setProjects,
  setCalendar,
  setSettings,
  setScaleDates,
  setScaleRender,
  setTasks,
  setTransformedData,
  setCurrentDate,
  setWrapRef,
  setEstimatedPerDay,
  addEstimatedPerDay,
} = plannerSlice.actions;

export default plannerSlice.reducer;
