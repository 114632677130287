import React, { ReactNode } from 'react';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import Chart from '../Chart';
import Controls from '../Controls';
import '../../styles/globals.css';
import './Gantt.css';
import { getInitialScrollOffset, getScaleDates } from '../../utils';
import { DragStepSizes, GanttDimensions } from '../../enums';
import { useSelector } from 'react-redux';
import { GanttDimensionsSettings } from '../../constants/DimensionsSettings';
import { GanttConsts } from '../../constants/GanttConsts';
import { DragStepOptions } from '../../constants/DragStepOptions';
import { RootState } from '../../../../app/store';

dayjs.extend(localizedFormat);

type GanttProps = {
  children: ReactNode | ReactNode[];
};

const Gantt: React.FC<GanttProps> & {
  Chart: typeof Chart;
  Controls: typeof Controls;
} = ({ children }: GanttProps) => {
  // const initialScaleDates = useMemo(() => {
  //   return getScaleDates();
  // }, []);

  // const { scaleDates } = useSelector((state: RootState) => state.planner);

  // const initialScrollOffset = useMemo(() => {
  //   return getInitialScrollOffset(GanttDimensions.DAY, scaleDates);
  // }, [scaleDates]);

  return <div className="gantt-wrap">{children}</div>;
};

Gantt.Chart = Chart;
Gantt.Controls = Controls;

export default Gantt;
