import { MenuItemType } from '../models/menu';

export const menuItemsInitial: MenuItemType[] = [
  {
    linkTo: '/',
    linkText: 'Inicio',
    isDisabled: false,
  },
  {
    linkTo: '/perfomance-indicator',
    linkText: 'KPI',
    isDisabled: true,
  },
  {
    linkTo: '/document-manager',
    linkText: 'Storage',
    isDisabled: true,
  },
  {
    linkTo: '/planner',
    linkText: 'Gestor de proyectos',
    isDisabled: true,
  },
];
