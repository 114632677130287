import React, { useContext, useMemo } from 'react';
import dayjs from 'dayjs';
import BarItem from '../BarItem';
import RepeteadBars from '../RepeteadBars';
import {
  BarItemDataType,
  EstimatePerDayType,
  OnBarDoubleClickType,
} from '../../../../../types';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../../app/store';

interface BarsItemsProps {
  data?: BarItemDataType;
  rowKey: string;
  color?: string;
  estimatePerDay?: EstimatePerDayType[];
  title: string;
  barKey: string;
  level: number;
  onBarDoubleClick?: OnBarDoubleClickType;
}

const BarsItems: React.FC<BarsItemsProps> = ({
  data,
  rowKey,
  color,
  estimatePerDay,
  title,
  barKey,
  level,
  onBarDoubleClick,
}) => {
  const { scaleRenderState, scaleDates } = useSelector(
    (state: RootState) => state.planner,
  );

  const firstRenderedDate = useMemo(() => {
    return scaleDates[scaleRenderState.overscanStartIndex];
  }, [scaleDates, scaleRenderState.overscanStartIndex]);

  const lastRenderedDate = useMemo(() => {
    return scaleDates[scaleRenderState.overscanStopIndex];
  }, [scaleDates, scaleRenderState.overscanStopIndex]);

  const startDate = useMemo(() => {
    return dayjs(data?.startDate).unix();
  }, [data?.startDate]);

  const endDate = useMemo(() => {
    return dayjs(data?.endDate).unix();
  }, [data?.endDate]);

  if (!data) {
    return null;
  }

  if (data.repeatType) {
    return (
      <RepeteadBars
        data={data}
        firstRenderedDate={firstRenderedDate}
        lastRenderedDate={lastRenderedDate}
        estimatePerDay={estimatePerDay}
        color={color}
        title={title}
        barKey={barKey}
        rowKey={rowKey}
        level={level}
        onBarDoubleClick={onBarDoubleClick}
      />
    );
  }

  if (startDate > lastRenderedDate || endDate < firstRenderedDate) {
    return null;
  }
  return (
    <BarItem
      key={data.startDate}
      rowKey={rowKey}
      title={title}
      barKey={barKey}
      level={level}
      estimatePerDay={estimatePerDay}
      color={color}
      startDate={startDate}
      endDate={endDate}
      repetead={true}
      onBarDoubleClick={onBarDoubleClick}
    />
  );
};

export default BarsItems;
