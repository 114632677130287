import { ChangeEvent, useEffect, useState } from 'react';
import {
  ModalCustom,
  ModalCustomSize,
  Select,
  Text,
  TextStyle,
  SvgImage,
  Checkbox,
  Textarea,
  Button,
  ButtonStyle,
  Badge,
  BadgeType,
  BadgeColor,
  DatePickerRange,
} from '@geoinnova/geoinnova-core';
import circlePlusIcon from '../../../assets/images/circle-plus-icon.svg';
import fileIcon from '../../../assets/images/file-icon.svg';
import noPhotoIcon from '../../../assets/images/icon-circle-user.svg';

import '../planner.css';
import { taskType } from '../../../models/task';
import { projectType } from '../../../models/project';

interface ModalDetailsProps {
  show: boolean;
  typeProject: boolean;
  project: projectType | null;
  mainTask: taskType | null;
  handleClose: () => void;
  handleChildModal: (task: taskType) => void;
}

const linkDummy = ['Reporte', 'Propuesta', 'Lorem ipsum dolor sit amet.'];

const projectBadgeDummy = [
  'Por iniciar',
  'Cliente Pepito Perez',
  'Cliente nuevo',
  'Geometalúrgia',
  'Se hará el proyecto y no se sabe la fecha de inicio',
];

const commentDummy = [
  {
    photo: noPhotoIcon,
    userName: 'Nombre Apellido Apellido',
    time: '13/03/24',
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam id tortor fermentum, vulputate lorem eu, tempor risus. Nulla feu.',
    myComment: true,
  },
  {
    photo: noPhotoIcon,
    userName: 'Nombre Apellido Apellido',
    time: '11/02/24',
    content:
      'Nam id tortor fermentum, vulputate lorem eu, tempor risus. Nulla feugiat turpis ut justo eleifend auctor. Vulputate lorem eu, tempor risus. Nulla feu.',
    myComment: false,
  },
];

export const ModalDetails = ({
  show,
  typeProject,
  project,
  mainTask,
  handleChildModal,
  handleClose,
}: ModalDetailsProps) => {
  const [displayForButtons, setDisplayForButtons] = useState<string>('none');
  const [textareaValue, setTextareaValue] = useState<string>('');
  const [childTasks, setChildTasks] = useState<taskType[]>([]);
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(new Date());

  const handleAddElement = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setDisplayForButtons('flex');
    setTextareaValue(e.target.value);
  };

  useEffect(() => {
    if (typeProject && project) {
      setChildTasks(project.tasks || []);
    } else if (!typeProject && mainTask) {
      setChildTasks(mainTask.subtasks || []);
    }
  }, []);

  return (
    <ModalCustom
      show={show}
      handleClose={handleClose}
      size={ModalCustomSize.xl}
      handleExpandIcon={() => {}}
      hasExpandIcon
    >
      <Text
        style={TextStyle.h2}
        className="color-primary70 detail-title-text mb-6"
        content={(typeProject ? project?.name : mainTask?.title) || ''}
      />
      <div className="container-fluid px-0">
        <div className="row">
          <div className="col-7 divider">
            <div className="pr-4">
              <Text
                content="Descripción"
                style={TextStyle.subtitle1}
                className="mb-3"
              />
              <div className="container-description">
                <Text
                  content={
                    (typeProject
                      ? project?.observation
                      : mainTask?.description) || 'Sin descripción'
                  }
                  style={TextStyle.body1}
                />
              </div>
              <div className="d-flex align-items-center justify-content-between mb-3 mt-6">
                <Text
                  content={typeProject ? 'Tareas' : 'Subtareas'}
                  style={TextStyle.subtitle1}
                />
                <SvgImage
                  src={circlePlusIcon}
                  className="add-task-icon"
                  onClick={() => {}}
                />
              </div>
              <div className="task-group-container">
                {childTasks.map((task, index) => {
                  return (
                    <div
                      className="d-flex align-items-center justify-content-between task-container"
                      key={index}
                    >
                      <a href="#" onClick={() => handleChildModal(task)}>
                        <Text
                          content={task.title}
                          style={TextStyle.body2}
                          className={`task-name color-primary30'
                        `}
                        />
                      </a>
                      <div className="d-flex align-items-center">
                        <Select
                          onChange={() => {}}
                          options={['Por hacer', 'En progreso', 'Finalizada']}
                          value={3}
                          width="10rem"
                          className="mr-4"
                          type="thin"
                          highlightColor={'var(--primary30)'}
                        />
                        <div className="d-flex align-items-center user-info-container">
                          <img
                            src={noPhotoIcon}
                            className="photo-image mr-3"
                            alt="User"
                            width={24}
                            height={24}
                          />
                          <Text
                            content={task.responsible?.abbreviation || '-'}
                            style={TextStyle.subtitle1}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <Text
                content="Comentarios"
                style={TextStyle.subtitle1}
                className="mt-6 mb-5"
              />
              <div className="d-flex mb-6">
                <img
                  src={noPhotoIcon}
                  className="photo-image mr-3"
                  alt="User"
                  width={24}
                  height={24}
                />
                <div className="w-100">
                  <Textarea
                    row={3}
                    onChange={e => handleAddElement(e)}
                    placeholder="Añadir un comentario"
                    value={textareaValue}
                  />
                  <div className="mt-3" style={{ display: displayForButtons }}>
                    <div className="mr-3">
                      <Button
                        label="Guardar"
                        style={ButtonStyle.primary}
                        customSize="btn-sm"
                        onClick={() => {
                          setDisplayForButtons('none');
                          setTextareaValue('');
                        }}
                      />
                    </div>
                    <Button
                      label="Cancelar"
                      style={ButtonStyle.outlineDark}
                      customSize="btn-sm"
                      onClick={() => {
                        setDisplayForButtons('none');
                        setTextareaValue('');
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="comments-list">
                {commentDummy.map((comment, index) => {
                  return (
                    <div className="d-flex mb-6" key={index}>
                      <img
                        src={comment.photo}
                        className="photo-image mr-3"
                        alt="User"
                        width={24}
                        height={24}
                      />
                      <div>
                        <div className="d-flex mb-3">
                          <Text
                            content={comment.userName}
                            style={TextStyle.body1}
                            className="mr-3"
                          />
                          <Text
                            content={comment.time}
                            style={TextStyle.body1}
                            className="color-neutral50"
                          />
                        </div>
                        <Text
                          content={comment.content}
                          style={TextStyle.body1}
                        />
                        <div className="d-flex mt-3">
                          <div onClick={() => {}} className="to-click mr-4">
                            <Text
                              content={
                                comment.myComment ? 'Editar' : 'Responder'
                              }
                              style={TextStyle.subtitle1}
                              className="color-primary100"
                            />
                          </div>
                          <div onClick={() => {}} className="to-click">
                            <Text
                              content={
                                comment.myComment ? 'Eliminar' : 'Resolver'
                              }
                              style={TextStyle.subtitle1}
                              className="color-primary100"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="col-5">
            <div className="pl-4">
              {typeProject ? (
                <>
                  <Text
                    content="Consultor principal"
                    style={TextStyle.subtitle1}
                    className="mb-3"
                  />
                  <div className="d-flex align-items-center">
                    <img
                      src={noPhotoIcon}
                      className="photo-image mr-3"
                      alt="User"
                      width={24}
                      height={24}
                    />
                    <Text
                      content={project?.projectOwner?.name || 'Sin asignar'}
                      style={TextStyle.body1}
                    />
                  </div>
                  <Text
                    content="Jefe de proyecto"
                    style={TextStyle.subtitle1}
                    className="mt-4 mb-3"
                  />
                  <div className="d-flex align-items-center">
                    <img
                      src={noPhotoIcon}
                      className="photo-image mr-3"
                      alt="User"
                      width={24}
                      height={24}
                    />
                    <Text
                      content={project?.projectManager?.name || 'Sin asignar'}
                      style={TextStyle.body1}
                    />
                  </div>
                </>
              ) : (
                <>
                  <Text
                    content="Responsable"
                    style={TextStyle.subtitle1}
                    className="mt-4 mb-3"
                  />
                  <div className="d-flex align-items-center">
                    <img
                      src={noPhotoIcon}
                      className="photo-image mr-3"
                      alt="User"
                      width={24}
                      height={24}
                    />
                    <Text
                      content={mainTask?.responsible?.name || 'Sin asignar'}
                      style={TextStyle.body1}
                    />
                  </div>
                </>
              )}
              {typeProject && (
                <>
                  <Text
                    content="Prioridad"
                    style={TextStyle.subtitle1}
                    className="mt-6 mb-3"
                  />
                  <Checkbox
                    onChange={() => {}}
                    id="priority-checkbox"
                    label="Este proyecto es prioritario."
                  />
                </>
              )}
              <div className="d-flex mt-6">
                <div>
                  <div className="d-flex">
                    <div className="dates-titles">
                      <Text
                        content="Fecha inicio"
                        style={TextStyle.subtitle1}
                        className="mb-3"
                      />
                    </div>
                    <div className="dates-titles">
                      <Text
                        content="Fecha de término"
                        style={TextStyle.subtitle1}
                        className="mb-3"
                      />
                    </div>
                  </div>
                  <DatePickerRange
                    startDate={startDate}
                    endDate={endDate}
                    onChangeEndDate={date => setEndDate(date)}
                    onChangeStartDate={date => setStartDate(date)}
                    disabled
                  />
                </div>
                <div>
                  <Text
                    content="Horas"
                    style={TextStyle.subtitle1}
                    className="mb-3"
                  />
                  <Badge
                    type={BadgeType.largeBold}
                    color={BadgeColor.dark}
                    text={
                      typeProject
                        ? '-'
                        : mainTask?.estimatedHours?.toString() || '-'
                    }
                    autoWidth
                  />
                </div>
              </div>
              {typeProject && (
                <Checkbox
                  onChange={() => {}}
                  id="deadline-checkbox"
                  label="Este proyecto tiene un límite de tiempo."
                  className="mt-3"
                />
              )}
              <div className="d-flex mt-6">
                <div className="mr-5">
                  <Text
                    content="Estado de avance"
                    style={TextStyle.subtitle1}
                    className="mb-3"
                  />
                  <Select
                    onChange={() => {}}
                    options={['Por hacer', 'En progreso', 'Finalizada']}
                    width="10.125rem"
                  />
                </div>
                {typeProject && (
                  <div>
                    <Text
                      content="ID propuesta"
                      style={TextStyle.subtitle1}
                      className="mb-3"
                    />
                    <Badge
                      type={BadgeType.largeBold}
                      color={BadgeColor.dark}
                      text="634"
                      autoWidth
                    />
                  </div>
                )}
              </div>
              {typeProject && (
                <>
                  <div className="d-flex align-items-center justify-content-between mb-3 mt-6">
                    <Text content="Links" style={TextStyle.subtitle1} />
                    <SvgImage
                      src={circlePlusIcon}
                      className="add-task-icon"
                      onClick={() => {}}
                    />
                  </div>
                  <div className="container-links mb-6">
                    {linkDummy.map((link, index) => {
                      return (
                        <div
                          className="d-flex align-items-center container-task-link"
                          onClick={() => {}}
                          key={index}
                        >
                          <SvgImage
                            src={fileIcon}
                            className="file-added-icon mr-3"
                            onClick={() => {}}
                          />
                          <Text
                            content={link}
                            style={TextStyle.body1}
                            className="color-primary100"
                          />
                        </div>
                      );
                    })}
                  </div>
                  <div>
                    <Text
                      content="Etiquetas"
                      style={TextStyle.subtitle1}
                      className="mb-3"
                    />
                    <div className="d-flex flex-wrap badges-container">
                      {projectBadgeDummy.map((badgeText, index) => {
                        return (
                          <Badge
                            type={BadgeType.largeNormal}
                            color={BadgeColor.secondary}
                            text={badgeText}
                            className="mr-5 mb-4"
                            key={index}
                            autoWidth
                          />
                        );
                      })}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </ModalCustom>
  );
};
