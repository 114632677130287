import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { globalType } from '../../models/global';
import { loginType } from '../../models/login';

const initialState: globalType = {
  showLoader: false,
  login: {
    username: '',
    password: '',
    loggedIn: false,
    messageError: '',
  },
  isReady: false,
};
const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setShowLoader: (state, action: PayloadAction<boolean>) => {
      state.showLoader = action.payload;
    },
    setLogin: (state, action: PayloadAction<loginType>) => {
      state.login.username = action.payload.username;
      state.login.password = action.payload.password;
      state.login.loggedIn = action.payload.loggedIn;
    },
    setUsername: (state, action: PayloadAction<string>) => {
      state.login.username = action.payload;
    },
    setPassword: (state, action: PayloadAction<string>) => {
      state.login.password = action.payload;
    },
    setLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.login.loggedIn = action.payload;
    },
    setIsReady: (state, action: PayloadAction<boolean>) => {
      state.isReady = action.payload;
    },
  },
});

export const {
  setShowLoader,
  setLogin,
  setIsReady,
  setLoggedIn,
  setPassword,
  setUsername,
} = globalSlice.actions;

export default globalSlice.reducer;
