import {
  KeyboardEventHandler,
  memo,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useDraggable } from '@dnd-kit/core';
import dayjs from 'dayjs';
import { DragTypes } from '../../../../../enums';
import { EstimatePerDayType, OnBarDoubleClickType } from '../../../../../types';
import 'tippy.js/dist/tippy.css';
import { TextStyle, Text, Input } from '@geoinnova/geoinnova-core';
import { differenceDay } from '../../../../../../../utils/utilities';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../../app/store';
import {
  addEstimatedPerDay,
  setEstimatedPerDay,
} from '../../../../../../../app/slices/plannerSlice';
import {
  addTaskTimeEstimatedPerDay,
  getTaskById,
  setTaskTimeEstimatedPerDay,
} from '../../../../../../../services/taskServices';
import './BarItem.css';
import moment from 'moment';

let onPointerDownTimeout: NodeJS.Timeout;

type BarItemProps = {
  title: string;
  rowKey: string;
  color?: string;
  estimatePerDay?: EstimatePerDayType[];
  barKey: string;
  startDate: number;
  endDate: number;
  repetead: boolean;
  level: number;
  onBarDoubleClick?: OnBarDoubleClickType;
};

const BarItem = memo<BarItemProps>(
  ({
    title,
    rowKey,
    color,
    estimatePerDay,
    barKey,
    startDate,
    endDate,
    repetead,
    level,
    onBarDoubleClick,
  }: BarItemProps) => {
    const dispatch = useDispatch();
    const [inputValue, setInputValue] = useState('');

    const { settings, scaleDates } = useSelector(
      (state: RootState) => state.planner,
    );
    const [showInput, setShowInput] = useState<boolean>(false);
    const [currentIndex, setCurrentIndex] = useState<number | null>(null);
    const { attributes, transform, activatorEvent } = useDraggable({
      id: barKey,
    });

    const countItem = useMemo(() => {
      var dateEnd = new Date(endDate * 1000);
      var dateStart = new Date(startDate * 1000);
      return differenceDay(dateStart, dateEnd);
    }, [endDate, startDate]);

    const startWidth = useMemo(() => {
      const pixels = (endDate - startDate) / settings.secondsInPixel;
      return pixels / countItem;
    }, [countItem, endDate, settings.secondsInPixel, startDate]);

    const maxWidth = useMemo(() => {
      return repetead ? (24 * 3600 - 1) / settings.secondsInPixel : 0;
    }, [repetead, settings.secondsInPixel]);

    const arrayEstimateDay: EstimatePerDayType[] | undefined = useMemo(() => {
      const result = [];
      const start = new Date(startDate * 1000);

      for (let i = 0; i < countItem; i++) {
        const currentDate = new Date(start);
        currentDate.setDate(start.getDate() + i);
        const formattedDate = currentDate.toISOString().split('T')[0];
        const existingEntry = estimatePerDay?.find(entry => {
          return entry.date === formattedDate;
        });

        if (existingEntry) {
          result.push(existingEntry);
        } else {
          result.push({
            id: i.toString(),
            date: currentDate,
            hours: 0,
          } as EstimatePerDayType);
        }
      }
      return result;
    }, [estimatePerDay, countItem, endDate, startDate]);

    const startPosition = useMemo(() => {
      return (startDate - scaleDates[0]) / settings.secondsInPixel;
    }, [scaleDates, settings.secondsInPixel, startDate]);

    const activeDragType = useMemo(() => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const target: any = activatorEvent?.target;

      return +target?.dataset?.dragtype;
    }, [activatorEvent?.target]);

    const delta = useMemo(() => {
      if (!transform) {
        return 0;
      }

      const maxDelta = maxWidth ? maxWidth - startWidth : null;
      const minDelta = settings.gridSize - startWidth;

      let newDelta =
        activeDragType === DragTypes.RESIZE_LEFT ? -transform.x : transform.x;

      if (newDelta !== 0 && activeDragType !== DragTypes.DRAG) {
        if (maxDelta !== null && newDelta > maxDelta) {
          newDelta = maxDelta;
        }

        if (newDelta < minDelta) {
          newDelta = minDelta;
        }
      }

      return newDelta;
    }, [activeDragType, maxWidth, settings.gridSize, startWidth, transform]);

    const translateStyle = useMemo(() => {
      if (activeDragType === DragTypes.DRAG) {
        return `translateX(${delta}px)`;
      }

      return `translateX(${
        activeDragType === DragTypes.RESIZE_LEFT ? -delta : 0
      }px)`;
    }, [activeDragType, delta]);

    const endTime = useMemo(() => {
      const deltaInSeconds = [DragTypes.RESIZE_RIGHT, DragTypes.DRAG]?.includes(
        activeDragType,
      )
        ? delta * settings.secondsInPixel
        : 0;

      return dayjs.unix(endDate + deltaInSeconds).format('L LTS');
    }, [activeDragType, delta, endDate, settings.secondsInPixel]);

    const getDayByPosition = (index: string): string => {
      const estimate = arrayEstimateDay?.find(item => item.id === index);
      if (estimate && level > 0) {
        return (estimate.hours || 0).toString();
      } else {
        return '';
      }
    };

    const onDoubleClick = useCallback(() => {
      if (onBarDoubleClick) {
        onBarDoubleClick(barKey);
      }
    }, [barKey, onBarDoubleClick]);

    const onPointerUp = useCallback(() => {
      if (onPointerDownTimeout) {
        clearTimeout(onPointerDownTimeout);
      }
    }, []);
    const onChangeInput = async (
      event: React.ChangeEvent<HTMLInputElement>,
    ) => {
      try {
        setInputValue(event.target.value);
      } catch (error) {
        console.log(error);
      }
    };

    const handleKeyDown = async (
      event: React.KeyboardEvent<HTMLInputElement>,
      index: string,
    ) => {
      try {
        if (event.key === 'Enter') {
          const newEstimatedHours = Number(inputValue);
          if (newEstimatedHours > 0) {
            const estimate = estimatePerDay?.find(item => item.id === index);
            if (estimate) {
              const id = estimate.id;
              dispatch(
                setEstimatedPerDay({
                  id,
                  hours: newEstimatedHours,
                }),
              );
              await setTaskTimeEstimatedPerDay(
                id.toString(),
                newEstimatedHours,
              );
            } else {
              const estimate = arrayEstimateDay?.find(
                item => item.id === index,
              );
              const estimateDate = estimate?.date as string;
              const taskId = rowKey.split('-key')[1];
              if (estimateDate) {
                const date = moment(estimateDate).format('YYYY-MM-DD');
                const newEstimatedResponse = await addTaskTimeEstimatedPerDay(
                  taskId,
                  newEstimatedHours,
                  date,
                );
                if (
                  newEstimatedResponse.status == 'success' &&
                  newEstimatedResponse.data
                ) {
                  const { data } = newEstimatedResponse;
                  const { id } = data;

                  dispatch(
                    addEstimatedPerDay({
                      id: taskId,
                      estimatedId: id,
                      hours: newEstimatedHours,
                      date,
                    }),
                  );
                }
              }
            }
          }
        }
      } catch (error) {
        console.log('error', error);
      }
    };

    return (
      <div>
        {arrayEstimateDay &&
          arrayEstimateDay?.length > 0 &&
          arrayEstimateDay.map((_item, index) => (
            <div key={_item.id}>
              <div
                className="gantt-bars-row-item-wrap"
                style={{
                  position: 'absolute',
                  height: '40px',
                  top: 0,
                  left: startPosition + startWidth * index,
                  width: startWidth,
                  transform: translateStyle,
                }}
              >
                <div
                  className="gantt-bars-row-item-bar"
                  style={{
                    backgroundColor:
                      _item.date.toString().slice(0, 3) === 'Sat' ||
                      _item.date.toString().slice(0, 3) === 'Sun'
                        ? 'var(--neutral30)'
                        : color,
                    cursor:
                      _item.date.toString().slice(0, 3) === 'Sat' ||
                      _item.date.toString().slice(0, 3) === 'Sun'
                        ? 'default'
                        : 'pointer',
                  }}
                  {...attributes}
                  onPointerUp={onPointerUp}
                  onDoubleClick={onDoubleClick}
                >
                  <div
                    onClick={() => {
                      setShowInput(true);
                      setCurrentIndex(index);
                    }}
                  >
                    {showInput === false || currentIndex !== index ? (
                      <Text
                        content={
                          _item.date.toString().slice(0, 3) === 'Sat' ||
                          _item.date.toString().slice(0, 3) === 'Sun'
                            ? ''
                            : getDayByPosition(_item.id.toString())
                        }
                        style={TextStyle.subtitle1}
                      />
                    ) : (
                      <Input
                        onChange={onChangeInput}
                        onKeyDown={e => handleKeyDown(e, _item.id.toString())}
                        defaultValue={(
                          getDayByPosition(_item.id.toString()) || ''
                        ).toString()}
                        name="input-edit"
                        inputWidth="2.4rem"
                        inputHeight="1.875rem"
                        errorSpace={false}
                      />
                    )}
                  </div>

                  {/* {barData.level === 0 &&
                    barData.closeToEndTime &&
                    new Array(countItem).fill(0)?.length - 1 === index && (
                      <SvgImage
                        src={iconClock}
                        className="icon-clock-close-time"
                      />
                    )} */}
                </div>
              </div>
            </div>
          ))}
      </div>
    );
  },
);

BarItem.displayName = 'BarItem';

export default BarItem;
