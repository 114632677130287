import dayjs from 'dayjs';
import _ from 'lodash';
import moment from 'moment';

export const getCurrentDay = () => {
  return dayjs(new Date()).format('DDMMYYYY');
};

export const getWeeksInYear = (startDate: string, endDate: string) => {
  let start = moment(startDate);
  if (start.isoWeekday() > 1) {
    start.add(7 - start.isoWeekday() + 1, 'days');
  }
  let end = moment(endDate);
  if (end.isoWeekday() < 7) {
    end.subtract(end.isoWeekday(), 'days');
  }

  const weeks = [];
  while (start <= end) {
    weeks.push(start.isoWeek());
    start.add(1, 'week');
  }

  return weeks;
};

export const differenceDay = (dateStart: Date, dateEnd: Date): number => {
  try {
    const startDate = new Date(dateStart);
    startDate.setHours(0, 0, 0, 0);
    const endDate = new Date(dateEnd);
    endDate.setHours(0, 0, 0, 0);

    const differenceInMilliseconds = endDate.getTime() - startDate.getTime();

    const differenceInDays = differenceInMilliseconds / (1000 * 3600 * 24);
    return Math.floor(differenceInDays);
  } catch (error) {
    return 0;
  }
};

export const getInitials = (name: string): string => {
  try {
    name = normalizeSpaces(name);
    const words = name.split(' ');
    let initials = words.map(word => word[0].toUpperCase());
    if (initials?.length > 3) {
      initials = initials.slice(0, 3);
    }
    return initials.join('');
  } catch (error) {
    return 'NN';
  }
};
export function normalizeSpaces(text: string): string {
  return text.replace(/\s+/g, ' ').trim();
}

export const generateRandom = (limit: number): number => {
  const randomNumber = Math.floor(Math.random() * limit) + 1;
  return randomNumber <= 0 ? 1 : randomNumber;
};

export const setDefaultDate = (
  dateAt: string | Date,
  isEndDay: boolean = false,
): string => {
  if (!_.isEmpty(dateAt)) {
    let currentDay = new Date(dateAt);
    if (isEndDay) {
      currentDay = new Date(
        currentDay.getUTCFullYear(),
        currentDay.getUTCMonth(),
        currentDay.getUTCDate(),
        23,
        59,
        59,
        0,
      );
    } else {
      currentDay = new Date(
        currentDay.getUTCFullYear(),
        currentDay.getUTCMonth(),
        currentDay.getUTCDate(),
        0,
        0,
        0,
        0,
      );
    }
    return currentDay.toISOString();
  }
  return '';
};
