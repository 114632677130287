import axios from 'axios';

const getCalendar = async () => {
  try {
    const url = `${process.env.REACT_APP_API_LAMBDA}/calendar`;
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export { getCalendar };
